<template>
    <div class="lounge-create">
        <StackRouterHeaderBar
            :class="{ 'show-title': showTitle }"
            :title="$translate('LOUNGE_MAKE')"
            :showTitle="showTitle"
        />
        <div class="content-wrapper">
            <div class="header">
                <div class="f-24 c-black f-medium" v-html="$translate('LOUNGE_MAKE')" />
                <div class="how-to-use" @click="onClickTip">
                    <i class="material-icons">info</i>
                    <span class="c-white f-12 f-bold" v-html="$translate('LOUNGE_HOW_TO_USE')" />
                </div>
            </div>
            <div class="contents">
                <div class="title m-b-32">
                    <div class="label" v-html="$translate('TITLE')" />
                    <TextareaWithX v-model="title" :isInputMode="true" :placeholder="$translate('TITLE_HERE')" />
                </div>
                <div class="content m-b-12">
                    <div class="label" v-html="$translate('CONTENT')" />
                    <TextareaWithX v-model="content" class="input-content" :placeholder="$translate('CONTENT_HERE')" />
                </div>
                <!-- <div class="desc">
                    <ul>
                        <li v-for="idx in 2" :key="idx" :class="{ 'm-b-10': idx === 1 }">
                            <p class="dot m-r-8" />
                            <p v-html="$translate(`LOUNGE_POST_MAKE_DESCRIPTION_${idx}`)" />
                        </li>
                    </ul>
                </div> -->
                <!-- <div class="f-24 c-black f-medium" v-html="$translate('LOUNGE_MAKE')" /> -->
                <div class="write-tips" @click="onClickWriteTip">
                    <i class="material-icons-outlined">help_outline</i>
                    <span class="f-14" v-html="$translate('LOUNGE_HOW_TO_WRITE')" />
                </div>
                <!-- https://bit.ly/3jpjbTW -->
                <PhotoInputArea class="m-b-40" :isMust="true" @submitPhoto="updatePhoto" @clearPhoto="clearPhoto" />
                <div class="examples m-b-32">
                    <div class="example-title" v-html="$translate('LOUNGE_EXAMPLE_TITLE')" />
                    <div v-if="$store.getters.me.gender == 0" class="example-container flex-row">
                        <div class="example">
                            <img class="photo" src="@/assets/images/lounge/lounge_ex_m1.png" />
                            <span class="text" v-html="$translate('LOUNGE_EXAMPLE_M_1')" />
                        </div>
                        <div class="example">
                            <img class="photo" src="@/assets/images/lounge/lounge_ex_m2.png" />
                            <span class="text" v-html="$translate('LOUNGE_EXAMPLE_M_2')" />
                        </div>
                        <div class="example">
                            <img class="photo" src="@/assets/images/lounge/lounge_ex_m3.png" />
                            <span class="text" v-html="$translate('LOUNGE_EXAMPLE_M_3')" />
                        </div>
                    </div>
                    <div v-if="$store.getters.me.gender == 1" class="example-container flex-row">
                        <div class="example">
                            <img class="photo" src="@/assets/images/lounge/lounge_ex_w1.png" />
                            <span class="text" v-html="$translate('LOUNGE_EXAMPLE_W_1')" />
                        </div>
                        <div class="example">
                            <img class="photo" src="@/assets/images/lounge/lounge_ex_w2.png" />
                            <span class="text" v-html="$translate('LOUNGE_EXAMPLE_W_2')" />
                        </div>
                        <div class="example">
                            <img class="photo" src="@/assets/images/lounge/lounge_ex_w3.png" />
                            <span class="text" v-html="$translate('LOUNGE_EXAMPLE_W_3')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomButton @click="submit" :label="$translate('MAKE_POST')" :disabled="disabled" />
    </div>
</template>

<script>
import PhotoInputArea from '@/components/app/PhotoInputArea'
import loungeService from '@/services/lounge'

export default {
    name: 'LoungeFeedCreatePage',
    data: () => ({
        showTitle: false,
        title: '',
        content: '',
        customPhoto: { blob: null, url: null },
    }),
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: `Enter_LoungeFeedCreatePage`,
            },
        })
    },
    computed: {
        disabled() {
            return !(this.title && this.content.trim() && this.customPhoto.blob)
        },
    },
    components: {
        PhotoInputArea,
    },
    methods: {
        updatePhoto(photo) {
            this.customPhoto = photo
        },
        clearPhoto() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
        },
        preparePayload(payload) {
            const formData = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
                formData.append(key, value)
            })

            return formData
        },
        async submit() {
            if (this.disabled) return

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Click_Lounge_Create_CTA`,
                },
            })

            const idx = await this.$modal.basic({
                body: '게시글을 등록합니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (!idx) return

            this.$loading(true)
            try {
                const payload = {
                    title: this.title,
                    content: this.content,
                }
                if (this.customPhoto.blob) payload.photo = this.customPhoto.blob
                const { msg } = await loungeService.create(this.preparePayload(payload))
                this.$store.dispatch('loadLoungePosts')
                this.$toast.success(msg)
                this.$bus.$emit('completeMakePost')
                this.$stackRouter.pop()
            } catch (e) {
                // 금지어 체크
                this.$modal.basic({
                    title: e.data.title,
                    body: e.data.msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } finally {
                this.$loading(false)
            }
        },
        onClickTip() {
            // this.$emit('close')
            this.$stackRouter.push({ name: 'LoungeIntroductionPage' })
        },
        onClickWriteTip() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `LoungeWritingPage_Click_PostGuide`,
                },
            })

            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://bit.ly/3jpjbTW',
            })
        },
    },
}
</script>

<style scoped lang="scss">
.lounge-create {
    height: calc(100vh - 81px);
    overflow-y: auto;

    .content-wrapper {
        padding: 0 16px;

        .header {
            margin-bottom: 40px;
            @include between;

            .how-to-use {
                background: $grey-09;
                padding: 3px 10px 3px 6px;
                border-radius: 12px;
                @include center;

                i {
                    color: white;
                    margin-right: 6px;
                    font-size: 13px;
                }
            }
        }

        .contents {
            .write-tips {
                background: $grey-01;
                padding: 7px 14px 7px 14px;
                border-radius: 18px;
                border: 1px solid $grey-03;
                color: $grey-09;
                width: fit-content;
                margin-left: 20vw;
                // align-self: center;
                @include center;

                i {
                    color: $grey-07;
                    margin-right: 6px;
                    font-size: 17px;
                }
            }

            .examples {
                .example-title {
                    font-size: 14px;
                    color: $grey-08;
                    margin-bottom: 16px;
                }
                .example-container {
                    width: 100%;
                    .example {
                        max-width: 120px;
                        text-align: center;
                        &:not(:last-child) {
                            margin-right: 12px;
                        }

                        .photo {
                            width: 100%;
                        }
                        .text {
                            font-size: 12px;
                            color: $grey-08;
                        }
                    }
                }
            }
        }

        .desc {
            padding: 16px;
            background: $grey-01;
            font-size: 12px;
            border-radius: 12px;
            margin-bottom: 32px;
            line-height: 18px;
            letter-spacing: -0.2px;
            @include f-regular;

            li {
                display: flex;
            }
        }

        .label {
            font-size: 14px;
            color: $grey-08;
            margin-bottom: 12px;
            @include f-medium;
        }

        .input-content {
            height: 200px;

            &::v-deep textarea {
                line-height: 22px;
            }
        }

        .dot {
            margin-top: 6px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $grey-07;
            @include flex-wrap;
        }
    }
}
</style>
