<template>
    <div class="photo-input">
        <div class="m-b-12 f-14">
            <span class="section-title f-medium">사진</span>
            <span class="f-regular">{{ this.isOption ? ' (선택)' : this.isMust ? ' (필수)' : '' }}</span>
        </div>
        <div class="photo center" :class="from === 'dating-review' ? 'white' : ''" @click="onClickPhoto">
            <div class="img-container" v-if="customPhoto.url">
                <i @click.stop="onClickClear" class="material-icons">clear</i>
                <img @click.stop="onClickPhoto" :src="customPhoto.url" />
            </div>
            <div v-else class="flex center">
                <i class="material-icons f-36 m-b-10">photo</i>
            </div>
        </div>
        <input
            ref="imageUploader"
            type="file"
            class="image-input display-none"
            @change="onChangeImage($event)"
            accept="image/*"
        />
    </div>
</template>

<script>
export default {
    name: 'PhotoInputArea',
    props: {
        from: String,
        isOption: Boolean,
        isMust: Boolean,
    },
    data: () => ({
        customPhoto: {
            blob: null,
            url: null,
        },
    }),
    computed: {
        optionExplain() {
            if (this.isOption) return this.$translate('PHOTO_OPTIONAL')
            else return this.$translate('PHOTO')
        },
    },
    methods: {
        onClickPhoto() {
            this.$refs.imageUploader.click()
        },
        onClickClear() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
            this.$emit('clearPhoto')
        },
        onChangeImage(e) {
            const file = e.target.files[0]
            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    this.customPhoto.url = URL.createObjectURL(croppedFile)
                    this.customPhoto.blob = croppedFile
                    this.$emit('submitPhoto', this.customPhoto)
                })
        },
    },
}
</script>

<style scoped lang="scss">
.section-title {
    @include f-medium;
}
.photo-input {
    margin-top: 32px;
}

.img-container {
    position: relative;
    width: 100%;
    height: 100%;

    i {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        color: white;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

.photo {
    $section-side-padding: 16px;
    $bottom-button-height: 80px;

    width: calc(100vw - #{$section-side-padding} * 2);
    height: calc(100vw - #{$section-side-padding} * 2);
    background-color: $grey-01;
    border-radius: 10px;
    border: 1px solid $grey-02;

    .btn {
        background-color: $grey-09;
        height: 40px;
        padding: 10px 30px;
        z-index: inherit;
    }

    &.white {
        background: white;
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
        border: 1px solid $grey-03;
    }
}
</style>
